
import React from 'react'
import { List, Footer, Swiper, NoticeBar, Ellipsis, Image, Tag } from 'antd-mobile'





const Y1menu = () => {
    const token = JSON.parse(localStorage.getItem('cx315_token'))
    const imgjsonz = token.pjson1.imgjson
    const mjson = token.pjson1.mjson
    const imgjson = imgjsonz.map((e) => (
        <Swiper.Item key={e.uid}>
            <Image src={e.url} />
        </Swiper.Item>
    ))
    return <>
        {/* 追溯默认页面，显示基本信息，若有活动=》渲染，若有找回信息=》渲染, */}
        <Swiper >
            {imgjson}
        </Swiper>



        <List style={{ '--font-size': '1em' }}>
            <List.Item extra={token.resq.dbcode} > 单元识别码</List.Item>
            <List.Item extra={token.resq.dbno}  >数据批次</List.Item>
            <List.Item extra={token.resq.dbtype === 'S' ? "最小销售单元" : "集合外包"}  >数据类型</List.Item>

            <List.Item description={<Ellipsis
                direction='end'
                content={`http://cx315.com/${token.resq.dbno}/${token.resq.dbtype}/${token.resq.dbcode}`}
                expandText='展开'
                collapseText='收起'
            />} >
                追溯网址
            </List.Item>
            <List.Item extra={<Tag
                color='success'
                fill='outline'
                style={{ '--background-color': '#c8f7c5', '--border-radius': '3px' }}
            >
                {token.e_json.s_num}
            </Tag>}>扫描次数</List.Item>
        </List>

        {mjson.map((m) => (
            <List style={{ '--font-size': '1em' }} >
                <NoticeBar content={m.mname} style={{ '--background-color': token.pjson1.mcv }} icon='' />
                {m.list.map((k) => (

                    <List.Item extra={<Ellipsis
                        direction='end'
                        content={k.second}
                        expandText='展开'
                        collapseText='收起'
                    />} >
                        {k.first}
                    </List.Item>

                ))}






            </List>
        )
        )
        }
       




        <Footer
            label='没有更多了'
            content='@ 2024-2026 cx315.com All rights reserved'

        ></Footer>

        <Footer label='-'></Footer>

    </>
}

export default Y1menu;