import React from 'react'
import { Steps } from 'antd-mobile'
const { Step } = Steps
const Y1menu1=()=>{

    return <>
    
    {/* 溯源信息页面，生命周期活动，若使用区块可信数据 & 有记录=》渲染,质检记录=》渲染，互动检验记录=》渲染， */}
    <Steps direction='vertical'>
          <Step
            title='填写机构信息'
            status='finish'
            description='完成时间：2020-12-01 12:30'
          />
          <Step
            title='签约机构'
            status='finish'
            description='完成时间：2020-12-01 12:30'
          />
          <Step
            title='关联服务区'
            status='finish'
            description='完成时间：2020-12-01 12:30'
          />
          <Step title='审批失败' status='error' />
        </Steps>
    </>
}

export default Y1menu1;